import React from "react";

import { UserDataInterface } from "../interfaces/user-data.interface";

interface ContextData {
  flags: { [key: string]: any };
  setFlags?: (flags) => void;
  userData: UserDataInterface | undefined;
}

/*
 * Initial data from context
 */
const initContext: ContextData = {
  flags: {},
  userData: {} as UserDataInterface,
};

export const AppContext = React.createContext(initContext);
