import { AxiosResponse } from 'axios';

import { ApiGatewayClient } from '../../utils/api-gateway-client.util';
import { apiGatewayClientConfig } from '../../config';
import { HttpMethod } from '../../utils/enums/http-method.enum';

/**
 * Class responsible for manage the API calls and data request of the Register View
 */
export class RegisterService {
    private client: ApiGatewayClient;

    /**
     * Instantiates a new identified ApiGatewayClient
     */
    constructor() {
        this.client = new ApiGatewayClient(apiGatewayClientConfig);
    }

    /**
     * Completes the registration of the user through the API
     * @param area
     * @param id
     */
    public completeRegister(
        area: string,
        id: string,
    ): Promise<AxiosResponse<{ message: string }>> {
        return this.client.fetch({
            path: '/auth/registration',
            method: HttpMethod.POST,
            body: {
                id,
                area,
            },
        });
    }

    /**
     * Returns the list of areas
     */
    public getAreas(): Promise<AxiosResponse<Array<string>>> {
        return this.client.fetch<Array<string>>({
            path: '/catalogs/areas',
            method: HttpMethod.GET,
        });
    }
}
