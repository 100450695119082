import { MegamenuRouteDefinition } from '@dh/bronson-react';
import { RouteDefinition } from '../routes/routes.type';

/**
 * This method validates routes and feature-flags in order
 * to return if a route component and path should be displayed.
 *
 * @param route RouteDefinition | MegamenuRouteDefinition, route to validate
 * @param flags flags configuration object
 * @returns {boolean}
 */
export const validateRouteWithFlag = (
    route: RouteDefinition | MegamenuRouteDefinition,
    flags,
): boolean => {
    const { flag, flagChild } = route;
    if (flag && flags[flag!]) {
        if (flagChild) {
            return flags[flag!].enabled && flags[flag][flagChild] === true;
        }
        return flags[flag!].enabled;
    } else {
        return true;
    }
};
