import * as serviceWorker from "./serviceWorker";
import App from "./App";
import React from "react";
import ReactDOM from "react-dom";
import "react-app-polyfill/ie11";
import "core-js/es/array";
import "core-js/es/promise";
import "core-js/es/string/includes";
import "core-js/es/object/values";
import "core-js/es/array/values";
import "./polyfill";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root"),
);

serviceWorker.unregister();
