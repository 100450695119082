export const zero = 0;
export const one = 1;
export const two = 2;
export const three = 3;
export const four = 4;
export const five = 5;
export const six = 6;
export const seven = 7;
export const eight = 8;
export const nine = 9;
export const ten = 10;
export const eleven = 11;
export const twelve = 12;
export const thirteen = 13;
export const fourteen = 14;
export const fifteen = 15;
export const sixteen = 16;
export const eighteen = 18;
export const nineteen = 19;
export const twenty = 20;
export const twentyTwo = 22;
export const thirty = 30;
export const fifty = 50;
export const sixty = 60;
export const hundred = 100;
export const twoHundred = 200;
export const threeTwenty = 320;
export const sixHundred = 600;
export const oneThousand = 1000;
export const fourThousand = 4000;

export const dayHours = 24;
export const secPerHour = 3600;
export const daysPerYear = 365;
export const milliseconds = 1000;
export const kilobyte = 1024;
