export enum HttpMethod {
    get = 'get',
    GET = 'GET',
    delete = 'delete',
    DELETE = 'DELETE',
    head = 'head',
    HEAD = 'HEAD',
    options = 'options',
    OPTIONS = 'OPTIONS',
    post = 'post',
    POST = 'POST',
    put = 'put',
    PUT = 'PUT',
    patch = 'patch',
    PATCH = 'PATCH',
    link = 'link',
    LINK = 'LINK',
    unlink = 'unlink',
    UNLINK = 'UNLINK',
}
