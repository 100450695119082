import { useKeycloak } from "@react-keycloak/web";
import { Route, HashRouter as Router, Routes } from "react-router-dom";
import { useEffect, useState } from "react";

import { AppContext } from "../context/app.context";
import { BronsonApp } from "../components/BronsonApp";
import ErrorPage from "../Views/Error";
import { HomeService } from "../Views/Home/home.service";
import Login from "../Views/Login";
import { PortalRoutes } from "./routes.config";
import { PrivateRoute } from "./utils";
import { UserDataInterface } from "../interfaces/user-data.interface";
import { validateRouteWithFlag } from "../utils/flags.util";
import { zero } from "../utils/constants";
import { BronsonSpinner, BronsonSpinnerProps } from "@dh/bronson-react";
import { forbiddenLabels, notFoundLabels } from "../Views/Error/error.labels";
import { getUserFirstName, getUserRole } from "../utils/keycloak-utils";

export const AppRouter = () => {
  const { keycloak, initialized } = useKeycloak();
  const userName = keycloak?.tokenParsed?.sub;
  const [userData, setUserData] = useState<UserDataInterface>();
  const [loading, setLoading] = useState(true);
  const [portalRoutes, setPortalRoutes] = useState(PortalRoutes);
  const [flags, setFlags] = useState({});
  const notFoundHTTPCode = 404;

  useEffect(() => {
    if (userName && keycloak?.authenticated) {
      const service = new HomeService(keycloak);
      service
        .getUserData(userName)
        .then((response) => {
          const tempUserData: UserDataInterface = Object.assign(
            {},
            response.data,
            {
              role: getUserRole(keycloak),
              name: getUserFirstName(keycloak),
            },
          );
          setUserData(tempUserData);
          setLoading(false);
        })
        .catch((err) => {
          if (err.response?.status === notFoundHTTPCode) {
            window.location.href = "#/register";
          } else {
            window.location.href = "#/login";
          }
          setLoading(false);
        });
    }
  }, [userName, keycloak]);

  useEffect(() => {
    const getFeatureFlags = async () => {
      const homeService = new HomeService(keycloak);
      const response = await homeService.getFeatureFlags().catch(() => ({}));
      setFlags(response);
    };
    if (keycloak?.authenticated) {
      getFeatureFlags();
    }
  }, [keycloak?.authenticated]);

  useEffect(() => {
    if (flags && Object.keys(flags).length !== zero) {
      const newPortalRoutes = PortalRoutes.filter((route) =>
        validateRouteWithFlag(route, flags),
      );
      setPortalRoutes(newPortalRoutes);
    }
  }, [flags]);

  if (initialized) {
    return (
      <AppContext.Provider
        value={{
          flags,
          setFlags,
          userData,
        }}
      >
        <Router basename="/">
          <BronsonApp userData={userData} loading={loading}>
            <Routes>
              {portalRoutes.map((elem, index) => (
                <Route
                  key={index}
                  path={elem.path}
                  element={
                    <PrivateRoute
                      path={elem.path}
                      component={elem.component}
                      allowedRoles={
                        elem.allowedRoles ? elem.allowedRoles : undefined
                      }
                    />
                  }
                />
              ))}
              <Route
                path="/forbidden"
                element={<ErrorPage {...forbiddenLabels} />}
              />
              <Route path="/login" element={<Login />} />
              <Route path="*" element={<ErrorPage {...notFoundLabels} />} />
            </Routes>
          </BronsonApp>
        </Router>
      </AppContext.Provider>
    );
  } else {
    const spinnerConfig: BronsonSpinnerProps = {
      showSpinner: true,
      spinnerWrapperClassModifier: "c-spinner--text",
      showSpinnerText: true,
      spinnerText: "Estamos configurando tu espacio de trabajo",
    };
    return <BronsonSpinner config={spinnerConfig}></BronsonSpinner>;
  }
};
