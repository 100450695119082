/**
 * Labels for the forbidden error view.
 */
export const forbiddenLabels = {
  errorCode: "Error 403",
  errorCodeDescription: "No autorizado",
  message: "Usted no está autorizado para",
  messageComplement: "visualizar este espacio.",
};

/**
 * Labels for the not found error view.
 */
export const notFoundLabels = {
  errorCode: "Error 404",
  errorCodeDescription: "Página no encontrada",
};
