import { Roles } from '../config';

/**
 * This array includes all roles allowed to access to file storage views.
 */
export const allowedFileStorageRoles = [
    Roles.admin,
    Roles.fssAdmin,
    Roles.fss,
    Roles.posAdmin,
    Roles.creditAdmin,
    Roles.mktCRM,
];
