/**
 * File storage labels.
 */
export const fileStorageLabels = {
    hero: {
        title: 'Gestión de documentos',
        description: 'Visualización de archivos',
        iconClass: 'save-cloud',
    },
};
