import Keycloak from 'keycloak-js';
import { userTokenDefinition } from '../config';
/*
 *  _  __               _             _      _    _ _   _ _     
 * | |/ /              | |           | |    | |  | | | (_) |    
 * | ' / ___ _   _  ___| | ___   __ _| | __ | |  | | |_ _| |___ 
 * |  < / _ \ | | |/ __| |/ _ \ / _` | |/ / | |  | | __| | / __|
 * | . \  __/ |_| | (__| | (_) | (_| |   <  | |__| | |_| | \__ \
 * |_|\_\___|\__, |\___|_|\___/ \__,_|_|\_\  \____/ \__|_|_|___/
 *            __/ |                                             
 *           |___/                                              
 *
 * This file contains several tools to be used together with the implementation of keycloak to retrieve, transform and use
 * keycloak tools into the BOM platform
 * /

 /**
 * This method retrieves the user role from the keycloak token or
 * null if the token does not contain any information 
 * @param keycloak instance
 * @return Role string or null
 */
export const getUserRole = (keycloak: Keycloak): string | null => {
    const roleLabelInKeycloakToken = userTokenDefinition.roleLabelInKeycloakToken;
    const role =
        keycloak.tokenParsed && keycloak.tokenParsed[roleLabelInKeycloakToken]
            ? keycloak.tokenParsed[roleLabelInKeycloakToken][0]
            : null;
    return role;
};

/**
 * This method retrieves the full name of the user from the keycloak token
 * @param keycloak
 */
export const getUserFullName = (keycloak: Keycloak): string =>
    keycloak.tokenParsed &&
    keycloak.tokenParsed[userTokenDefinition.nameLabelInKeycloakToken]
        ? keycloak.tokenParsed[userTokenDefinition.nameLabelInKeycloakToken]
        : '';

/**
 * This method retrieves the first name of the user from the keycloak token
 * @param keycloak
 */
export const getUserFirstName = (keycloak: Keycloak): string =>
    keycloak.tokenParsed &&
    keycloak.tokenParsed[userTokenDefinition.firstNameLabelInKeycloakProfile]
        ? keycloak.tokenParsed[userTokenDefinition.firstNameLabelInKeycloakProfile]
        : '';

/**
 * This method retrieves the user uuid registered in Keycloak from the
 * Keycloak token
 * @param keycloak
 */
export const getUserId = (keycloak: Keycloak) => {
    return keycloak.tokenParsed &&
        keycloak.tokenParsed[userTokenDefinition.userIdInKeycloakToken]
        ? keycloak.tokenParsed[userTokenDefinition.userIdInKeycloakToken]
        : '';
};

/**
 * This method retrieves the user's email from the keycloak's token
 */
export const getUserEmail = (keycloak: Keycloak) => {
    const emailLabelInToken = 'email';
    return keycloak.tokenParsed && keycloak.tokenParsed[emailLabelInToken]
        ? keycloak.tokenParsed[emailLabelInToken]
        : '';
};
