import { RegisterService } from "./register.service";
import { useKeycloak } from "@react-keycloak/web";
import { BronsonToastNotification, BronsonToastProps } from "@dh/bronson-react";
import React, { useEffect, useRef, useState } from "react";

import heroImage from "../../assets/img/hero/computers.jpg";

export const Register: React.FC = () => {
  const service = useRef(new RegisterService());
  const keycloak = useKeycloak();
  const [areas, setAreas] = useState<Array<string>>();
  const [area, setArea] = useState("");
  const [loading, setLoading] = useState(false);
  const [isToastNotificationVisible, setToastNotificationVisibility] =
    React.useState(false);

  const registerArea = () => {
    const userId = keycloak.keycloak?.tokenParsed?.sub;
    if (userId) {
      setLoading(true);
      service.current
        ?.completeRegister(area, userId)
        .then(() => {
          window.location.href = "/";
        })
        .catch(() => {
          setToastNotificationVisibility(true);
        });
    }
  };

  useEffect(() => {
    service.current
      .getAreas()
      .then((areaList) => setAreas(areaList.data))
      .catch(() => null);
  }, []);

  const toastNotificationConfig: BronsonToastProps = {
    isVisible: isToastNotificationVisible,
    type: "error",
    content:
      "Ocurrio un error al intentar guardar la información, intente más tarde.",
  };

  return (
    <div>
      <BronsonToastNotification {...toastNotificationConfig} />
      <div className="c-hero-teaser c-hero-teaser--auto">
        <img
          src={heroImage}
          sizes="100vw"
          className="c-hero-teaser__image"
          alt=""
        />
        <div id="js-hero-teaser-content" className="c-hero-teaser__content">
          <div className="o-page-wrap">
            <header className="c-hero-teaser__heading">
              <div className="c-hero-teaser__title">
                <h1>
                  <span className="c-hero-teaser__title-row">Registro</span>
                </h1>
              </div>
            </header>
          </div>
        </div>
      </div>
      <section>
        <div className="o-component-wrapper u-mt-xsmall@s u-mt-large u-mb-large">
          <div className="o-page-wrap o-page-wrap--xsmall">
            {!loading && (
              <div className="o-fieldset u-mb">
                <div className="o-fieldset__row">
                  <label>Selecciona tu área para completar tu registro</label>
                  <div className="c-input  c-input--select">
                    <select
                      className="c-input__input"
                      onChange={(event) => {
                        setArea(event.target.value);
                      }}
                      defaultValue=""
                    >
                      <option disabled value=""></option>
                      {areas &&
                        areas?.map((areaItem, key) => (
                          <option key={key} value={areaItem}>
                            {areaItem}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="u-1/3 u-1/1@s">
                  <button
                    className="c-btn c-btn--full"
                    onClick={registerArea}
                    disabled={area ? false : true}
                  >
                    Continuar
                  </button>
                </div>
              </div>
            )}
            {loading && (
              <div
                className="c-spinner  c-spinner--center"
                aria-live="polite"
                aria-busy="true"
              >
                <div className="c-spinner__loader"></div>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};
