import { Navigate } from "react-router-dom";
import { useEffect } from "react";
import { useKeycloak } from "@react-keycloak/web";

/**
 * The Login View will handle the redirection of users accordingly
 * to the path they are trying to reach and their authenticated status
 */
const LoginPage = () => {
  const { keycloak } = useKeycloak();
  // const navigate = useNavigate();

  // TODO: capture real `from` from router
  const { from } = { from: { pathname: "/" } };

  useEffect(() => {
    if (keycloak && !keycloak.authenticated) {
      keycloak.login();
    }
  }, [keycloak]);

  if (keycloak && keycloak.authenticated) return <Navigate to={from} />;

  return <div>redirecting</div>;
};

export default LoginPage;
