import Keycloak from 'keycloak-js';

import { ApiGatewayClient } from './utils/api-gateway-client.util';
import { apiGatewayClientConfig } from './config';
import { HttpMethod } from './utils/enums/http-method.enum';

/**
 * Class responsible for manage the API calls and data request of the App
 */
export class AppService {
    protected client: ApiGatewayClient;
    public readonly dealerOnlinePath = '/dealer-online/bom';
    public readonly controllerPathCatalogs = '/catalogs';
    public readonly controllerPathFeatureFlags = '/feature-flags';

    public readonly postHeaders = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    };

    /**
     * Instantiates a new ApiGatewayClient
     */
    constructor(keycloak: Keycloak, invokerUrl?: string) {
        const config = { ...apiGatewayClientConfig };
        config.authorization = keycloak.idToken;
        if (invokerUrl) {
            config.invokerUrl = invokerUrl;
        }
        this.client = new ApiGatewayClient(config);
    }

    /**
     * Method to get the feature flags catalog.
     * @returns list of feature flags
     */
    public async getFeatureFlags(): Promise<{ any }> {
        return this.client
            .fetch<{ any }>({
                path: `${this.controllerPathFeatureFlags}/flags`,
                method: HttpMethod.GET,
            })
            .then((response) => response.data);
    }
}
