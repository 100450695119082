import React from "react";
import { BronsonSpinner, BronsonSpinnerProps } from "@dh/bronson-react";

import { UserDataInterface } from "../../interfaces/user-data.interface";

export const BronsonApp: React.FC<{
  userData?: UserDataInterface;
  loading?: boolean;
}> = (props) => {
  /*
   * +-+-+-+-+-+-+-+-+
   * |H|a|n|d|l|e|r|s|
   * +-+-+-+-+-+-+-+-+
   */
  /**
   * Spinner configuration definition
   */
  const spinnerConfig: BronsonSpinnerProps = {
    showSpinner: true,
    spinnerWrapperClassModifier: "c-spinner--text",
    showSpinnerText: true,
    spinnerText: "Estamos configurando tu espacio de trabajo",
  };

  /*
   * +-+-+-+-+ +-+-+-+-+-+-+
   * |M|a|i|n| |R|e|n|d|e|r|
   * +-+-+-+-+ +-+-+-+-+-+-+
   */

  if (props.loading) {
    return (
      <div>
        <BronsonSpinner config={spinnerConfig}></BronsonSpinner>
        {/* Even if the component is loading props.children should be render to allow redirection functionality, 
                    for example on log out */}
        <div className="u-hide">{props.children}</div>
      </div>
    );
  } else {
    return (
      <div style={{ overflow: "hidden" }}>
        {/* App Wrapped Content */}
        <main className="o-main">{props.children}</main>
      </div>
    );
  }
};
