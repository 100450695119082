import axios, { AxiosResponse } from 'axios';

import { ApiGatewayClientConfig } from './interfaces/api-gateway-client-config.interface';
import { ApiGatewayFetchParams } from './interfaces/api-gateway-client-fetch-params.interface';
import { HttpRequestParams } from './interfaces/http-request-params.interface';
import { SimpleHttpClientConfig } from './interfaces/simple-http-client.config.interface';
import { one, zero } from './constants/var.const';

/**
 * Class responsible for making signed aws api gateway requests
 */
export class ApiGatewayClient {
    private endpoint: string;
    private pathComponent: string;
    private simpleHttpClientConfig: SimpleHttpClientConfig;
    private apiGatewayClientConfig: ApiGatewayClientConfig;
    private replaceParamsSeparator = '%%R%%';

    /**
     * Getter for the separator code that it's used in the code
     */
    public get getSeparator() {
        return this.replaceParamsSeparator;
    }

    /**
     * Initializes the derived configurations based on the received Configuration
     * @param apiGatewayClientConfig security data for making the signed request
     */
    constructor(apiGatewayClientConfig: ApiGatewayClientConfig) {
        apiGatewayClientConfig.defaultAcceptType =
            apiGatewayClientConfig.defaultAcceptType || 'application/json';
        apiGatewayClientConfig.defaultContentType =
            apiGatewayClientConfig.defaultContentType || 'application/json';

        this.apiGatewayClientConfig = apiGatewayClientConfig;

        const search = /(^https?:\/\/[^/]+)/g.exec(apiGatewayClientConfig.invokerUrl);
        this.endpoint = search ? search[1] : '';
        this.pathComponent = apiGatewayClientConfig.invokerUrl.substring(
            this.endpoint.length,
        );
        this.simpleHttpClientConfig = {
            endpoint: this.endpoint,
            defaultContentType: apiGatewayClientConfig.defaultContentType,
            defaultAcceptType: apiGatewayClientConfig.defaultAcceptType,
        };
    }

    /**
     * Method that replaces the Separator (defined in this class) with
     * the nextToken param
     * @param query query with the placeholder
     * @param token token to be injected
     */
    private injectTokenToQuery(query: string, token: string) {
        return query.replace(this.getSeparator, ` nextToken:"${token}" `);
    }

    /**
     * Encapsulated method to inject headers to the request
     * @param request
     * @returns
     */
    private handlerHeaders(request: HttpRequestParams): HttpRequestParams {
        // Attach the apiKey to the headers request if one was provided
        if (this.apiGatewayClientConfig.apiKey) {
            request.headers['x-api-key'] = this.apiGatewayClientConfig.apiKey;
        }

        // Attach the authorization header with the idToken from Keycloak
        if (this.apiGatewayClientConfig.authorization) {
            request.headers.Authorization = this.apiGatewayClientConfig.authorization;
        }

        return request;
    }

    /**
     * Method that performs a fetch on the given data but will keep calling
     * fetch until no nextToken is found
     * @param requestParams request object data
     * @param tokenPath string to identify the path to Token
     * @param injectableQuery query string with the replaceable code
     */
    public fetchAll<T>(
        requestParams: ApiGatewayFetchParams,
        tokenPath: string,
        injectableQuery: string,
    ): Promise<AxiosResponse<T>> {
        return new Promise(async (resolve, reject) => {
            let accumulativeResponse;
            let response;
            do {
                try {
                    if (accumulativeResponse && typeof requestParams.body === 'object')
                        requestParams.body = {
                            query: this.injectTokenToQuery(
                                injectableQuery,
                                response.data.data[tokenPath].nextToken,
                            ),
                        };

                    response = await this.fetch(requestParams);

                    if (!accumulativeResponse) accumulativeResponse = response;
                    else if (
                        accumulativeResponse.data.data[tokenPath] &&
                        accumulativeResponse.data.data[tokenPath].items
                    ) {
                        accumulativeResponse.data.data[tokenPath].items =
                            accumulativeResponse.data.data[tokenPath].items.concat(
                                response.data.data[tokenPath].items,
                            );
                    }
                } catch (e) {
                    reject(e);
                }
            } while (response && response.data.data[tokenPath].nextToken);
            resolve(accumulativeResponse);
        });
    }

    /**
     * Sign and sends the request to the configured api gateway client
     *
     * @param requestParams request data
     */
    public fetch<T>(requestParams: ApiGatewayFetchParams): Promise<AxiosResponse<T>> {
        requestParams.params = requestParams.params || {};
        requestParams.additionalParams = requestParams.additionalParams || {};

        const request: HttpRequestParams = {
            verb: requestParams.method,
            path: this.pathComponent + requestParams.path,
            headers: this.parseParametersToObject(requestParams.params, []),
            queryParams: this.parseParametersToObject(requestParams.params, []),
            body: requestParams.body,
        };

        this.handlerHeaders(request);

        if (
            request.body === undefined ||
            request.body === '' ||
            request.body === null ||
            Object.keys(request.body).length === zero
        ) {
            request.body = undefined;
        }

        // If the user specified any additional headers or query params that may not have been modeled
        // merge them into the appropriate request properties
        request.headers = this.mergeInto(
            request.headers,
            requestParams.additionalParams.headers,
        );
        request.queryParams = this.mergeInto(
            request.queryParams,
            requestParams.additionalParams.queryParams,
        );

        // Call the selected http client to make the request, returning a promise once the request is sent
        return this.makeRequest<T>(request);
    }

    /**
     * Converts the signed request data into an Axios request and sends it
     *
     * @param request
     */
    private makeRequest<T>(request: HttpRequestParams): Promise<AxiosResponse<T>> {
        let url = this.simpleHttpClientConfig.endpoint.concat(request.path);

        request.queryParams = request.queryParams || {};
        request.headers = request.headers || {};

        if (request.headers['Content-Type'] === undefined) {
            request.headers['Content-Type'] =
                this.simpleHttpClientConfig.defaultContentType;
        }

        if (request.headers.Accept === undefined) {
            request.headers.Accept = this.simpleHttpClientConfig.defaultAcceptType;
        }

        request.body = request.body || '';

        const queryString = this.buildCanonicalQueryString(request.queryParams);
        if (queryString !== '') {
            url += '?' + queryString;
        }

        return axios.request<T>({
            method: request.verb,
            url,
            headers: request.headers,
            data: request.body,
        });
    }

    /**
     * Converts an object into a query param string
     *
     * @param queryParams
     */
    private buildCanonicalQueryString(queryParams) {
        if (Object.keys(queryParams).length) {
            return '';
        }

        let canonicalQueryString = '';
        for (const property in queryParams) {
            if (queryParams.hasOwnProperty(property)) {
                canonicalQueryString += `${encodeURIComponent(
                    property,
                )}=${encodeURIComponent(queryParams[property])}&`;
            }
        }

        return canonicalQueryString.substring(zero, canonicalQueryString.length - one);
    }

    /**
     * Converts params to objects
     */
    private parseParametersToObject(params, keys): any {
        if (params === undefined) {
            return {};
        }
        const objectTemp = {};
        for (let i = 0; i < keys.length; i++) {
            objectTemp[keys[i]] = params[keys[i]];
        }
        return objectTemp;
    }

    /**
     * Merge props
     */
    private mergeInto(baseObj, additionalProps) {
        const _typeof =
            typeof Symbol === 'function' && typeof Symbol.iterator === 'symbol'
                ? function (obj) {
                      return typeof obj;
                  }
                : function (obj) {
                      return obj &&
                          typeof Symbol === 'function' &&
                          obj.constructor === Symbol &&
                          obj !== Symbol.prototype
                          ? 'symbol'
                          : typeof obj;
                  };
        if (
            baseObj == null ||
            'object' != (typeof baseObj === 'undefined' ? 'undefined' : _typeof(baseObj))
        )
            return baseObj;
        const merged = baseObj.constructor();
        for (const attr in baseObj) {
            if (baseObj.hasOwnProperty(attr)) merged[attr] = baseObj[attr];
        }
        if (
            null == additionalProps ||
            'object' !=
                (typeof additionalProps === 'undefined'
                    ? 'undefined'
                    : _typeof(additionalProps))
        )
            return baseObj;
        for (const attr in additionalProps) {
            if (additionalProps.hasOwnProperty(attr))
                merged[attr] = additionalProps[attr];
        }
        return merged;
    }
}
