import { MegamenuRouteDefinition } from '@dh/bronson-react';

import { FileStorage } from '../Views/FileStorage';
import { Register } from '../Views/Register';
import { allowedFileStorageRoles } from './roles.config';
import { RouteDefinition, RoutesByPathResponse } from './routes.type';

/**
 * This object defines the navigation routes of the system and includes
 * path, component to be render, exact (for exact match see React Router) and
 * the allowed roles to consume the path.
 *
 * Add here new paths for navigation
 */
export const PortalRoutes: RouteDefinition[] = [
    {
        component: Register,
        path: '/register',
        showInNavbar: false,
    },
    {
        component: FileStorage,
        path: '/',
        exact: true,
        showInNavbar: false,
    },
    {
        component: FileStorage,
        path: '/fs-documents-view',
        label: 'VISUALIZACIÓN DE DOCUMENTOS',
        allowedRoles: allowedFileStorageRoles,
        showInNavbar: false,
        flag: 'fileStorage',
        flagChild: 'documentsVisualization',
    },
];

export const menuRouteDefinition: Array<MegamenuRouteDefinition> = [
    {
        label: 'FILE STORAGE',
        allowedRoles: allowedFileStorageRoles,
        img: 'laptop-id',
        flag: 'fileStorage',
        siblings: [
            {
                label: 'VISUALIZACIÓN DE DOCUMENTOS',
                path: '/fs-documents-view',
                allowedRoles: allowedFileStorageRoles,
                flag: 'fileStorage',
                flagChild: 'documentsVisualization',
            },
        ],
    },
];

/**
 * This method will return an object containing all the definition of
 * PortalRoutes arranged in an object where the main key is the path of the
 * route e.g [{component:x, path:'/path'}] => {'/path': {component:x, path:'/path'}}
 */
const createRoutesKeyByPath = (): RoutesByPathResponse => {
    const routesKeyByPath = {};
    PortalRoutes.forEach((elem) => {
        if (!routesKeyByPath[elem.path]) {
            routesKeyByPath[elem.path] = elem;
        }
    });

    return routesKeyByPath;
};

export const RoutesKeyByPath = createRoutesKeyByPath();
