import {
DataCard,
} from '@dh/bronson-react';

/**
 * DataCard initialization
 */
export const recordInit: DataCard = {
    id: '',
    title: '',
    subtitleLabel: '',
    subtitleValue: '',
    information: [],
};

/**
 * Months
 */
export const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
];

/**
 * Constant of Brand DWA.
 */
export const DasWeltAuto = 'dwa';
