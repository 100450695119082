import Keycloak from 'keycloak-js';
import { keycloakConfig } from '../config';

/**
 * This class will encapsulate the required functionality to use keycloak as our needs required it
 */
export class KeycloakImplementation {
    private config: Keycloak.KeycloakConfig;
    private keycloakInstance: Keycloak;
    private ready: boolean;

    constructor() {
        this.config = keycloakConfig;
        this.keycloakInstance = new Keycloak(this.config);
        this.ready = false;
    }

    /**
     * Gets the keycloak instance created in the constructor
     */
    get instance() {
        return this.keycloakInstance;
    }

    /**
     * Returns if the keycloak session is ready
     */
    get isReady() {
        return this.ready;
    }

    /**
     * Set the initial configuration on run time for the keycloak instance
     */
    public keycloakProviderInitConfig: Keycloak.KeycloakInitOptions = {
        onLoad: 'check-sso',
        checkLoginIframe: false,
    };

    /**
     * This event that will be fired when a new keycloak instance is created
     * @param event
     * @param error
     */
    // public onKeycloakEvent = (event: eventType, error) => {
    public onKeycloakEvent = () => {
        // console.log('onKeycloakEvent', event, error);
    };

    /**
     * This event will be fired when the tokens from keycloak are received
     * @param tokens
     */
    // public onKeycloakTokens = async (tokens: object) => {
    public onKeycloakTokens = async () => {
        return true;
    };
}
