import React from "react";
import { ReactKeycloakProvider } from "@react-keycloak/web";

import { AppRouter } from "./routes";
import { KeycloakImplementation } from "./utils/keycloak";
import "./app.styles.css";

/**
 * Main Application component to render all the application
 */
class App extends React.PureComponent {
  // keycloak implementation of the keycloak-js
  public keycloak = new KeycloakImplementation();

  constructor(props) {
    super(props);
    this.state = {
      isReady: false,
    };
  }

  public render() {
    return (
      <ReactKeycloakProvider
        authClient={this.keycloak.instance}
        initOptions={this.keycloak.keycloakProviderInitConfig}
      >
        <AppRouter />
      </ReactKeycloakProvider>
    );
  }
}

export default App;
