// import Keycloak from 'keycloak-js';
import { ApiGatewayClientConfig } from './utils/interfaces/api-gateway-client-config.interface';
import { IndentityPoolConfigInterface } from './interfaces/identity-pool-config.interface';
import { UserTokenDefinition } from './interfaces/user-data.interface';
import { kilobyte, oneThousand, ten, three, twenty, zero } from './utils/constants';

/**
 * Configuration file for the project
 */

/**
 * Initial configuration for the keycloak instance, this substitute the usual
 * keycloak.json that you normal put in /public
 */
export const keycloakConfig = {
    realm: process.env.REACT_APP_REALM || 'vwfs',
    url: process.env.REACT_APP_URL || 'https://dev.identity.digitalhub.vwfs.io/auth',
    clientId: process.env.REACT_APP_CLIENT_ID || 'backoffice',
};

export const stage = () => {
    const prevStage = process.env.REACT_APP_CUSTOM_NODE_ENV?.slice(zero, three) || 'dev';
    switch (prevStage) {
        case 'dev':
        case 'int':
            return prevStage;
        case 'con':
            return 'cons';
        case 'pro':
            return 'prod';
    }
};
export const bucketBFF = 'bff-upload-resource-bucket';

/**
 * This definition maps the keycloak token nodes to a variable,
 * this to refer token elements later in the code and just change
 * this reference and not all implementations
 */
export const userTokenDefinition: UserTokenDefinition = {
    roleLabelInKeycloakToken: 'backoffice_roles',
    nameLabelInKeycloakToken: 'name',
    userIdInKeycloakToken: 'sub',
    firstNameLabelInKeycloakProfile: 'given_name',
};

/**
 * Cognito required variables
 */
export const identityPoolConfig: IndentityPoolConfigInterface = {
    identityPoolId:
        process.env.REACT_APP_IDENTITY_POOL_ID ||
        'ca-central-1:26941ed0-d6e7-417f-9c8e-6d041a815b85',
    openIdProviderUrl:
        process.env.REACT_APP_OPEN_ID_URL ||
        'dev.identity.digitalhub.vwfs.io/auth/realms/vwfs',
};

/**
 * Back Office Manager back API credentials
 */
export const apiGatewayClientConfig: ApiGatewayClientConfig = {
    region: 'ca-central-1',
    invokerUrl: process.env.REACT_APP_API_URL || 'https://dev.api.bom.nar.vwfs.io',
    authorization: '',
};

/**
 * Glue jobs name
 */
export const bonusFleetGlueJob = process.env.REACT_APP_BONUS_GLUE_JOB || 'BonusFleet job';
export const campaignGlueJob = process.env.REACT_APP_CAMPAIGN_GLUE_JOB || 'Campaign job';
export const customerGlueJob = process.env.REACT_APP_CUSTOMER_GLUE_JOB || 'Customer job';
export const incentiveGlueJob =
    process.env.REACT_APP_INCENTIVE_GLUE_JOB || 'Incentive job';
export const insuranceGlueJob =
    process.env.REACT_APP_INSURANCE_GLUE_JOB || 'Insurance job';
export const terminationGlueJob =
    process.env.REACT_APP_TERMINATION_GLUE_JOB || 'Termination job';
export const renewalGlueJob = process.env.REACT_APP_RENEWAL_GLUE_JOB || 'Renewal job';
export const feeGrantGlueJob =
    process.env.REACT_APP_FEE_GRANT_GLUE_JOB || 'Fee Grant job';
export const loyaltyGlueJob =
    process.env.REACT_APP_FEE_LOYALTY_GLUE_JOB || 'Loyalty program job';
export const repepGlueJob = process.env.REACT_APP_REPEP_GLUE_JOB || 'Repep job';

/**
 * Roles definition for the application
 */
export enum Roles {
    'admin' = 'admin',
    'credit' = 'credit',
    'creditAdmin' = 'credAdmin',
    'mktAdmin' = 'mktAdmin',
    'mktCRM' = 'mktCRM',
    'risks' = 'risks',
    'commercial' = 'commercial',
    'commercialAdmin' = 'comAdmin',
    'commercialZM' = 'comZoneManager',
    'marketing' = 'marketing',
    'insurance' = 'insurance',
    'customerService' = 'customerService',
    'customerServiceAdmin' = 'customerServiceAdmin',
    'humanResources' = 'humanResources',
    'informationTechnologies' = 'informationTechnologies',
    'internalAudit' = 'internalAudit',
    'legalAndCompliance' = 'legalAndCompliance',
    'controllingAndPurchasing' = 'controllingAndPurchasing',
    'accounting' = 'accounting',
    'dealerOnline' = 'dealerOnline',
    'masterPO' = 'masterPO',
    'digitalInnovation' = 'digitalInnovation',
    'fslAdmin' = 'fslAdmin',
    'ssMkt' = 'ssMkt',
    'mktQuote' = 'mktQuote',
    'fssAdmin' = 'fssAdmin',
    'fss' = 'fss',
    'pos' = 'pos',
    'posAdmin' = 'posAdmin',
    'CSDigitalSignature' = 'CSDigitalSignature',
    'contabilidadCXC' = 'contabilidadCXC',
}
/**
 * Inverted definition of roles to get property according to the role value when
 * you want to use the properties instead but the value was provided
 */
export enum RolesMapping {
    'admin' = 'admin',
    'credit' = 'credit',
    'credAdmin' = 'creditAdmin',
    'mktAdmin' = 'mktAdmin',
    'risks' = 'risks',
    'commercial' = 'commercial',
    'comAdmin' = 'commercialAdmin',
    'comZoneManager' = 'commercialZM',
    'marketing' = 'marketing',
    'insurance' = 'insurance',
    'customerService' = 'customerService',
    'humanResources' = 'humanResources',
    'informationTechnologies' = 'informationTechnologies',
    'internalAudit' = 'internalAudit',
    'legalAndCompliance' = 'legalAndCompliance',
    'controllingAndPurchasing' = 'controllingAndPurchasing',
    'businessEngineering' = 'businessEngineering',
    'accounting' = 'accounting',
    'masterPO' = 'masterPO',
    'digitalInnovation' = 'digitalInnovation',
    'fslAdmin' = 'fslAdmin',
    'mktQuote' = 'mktQuote',
    'fssAdmin' = 'fssAdmin',
    'fss' = 'fss',
    'pos' = 'pos',
    'posAdmin' = 'posAdmin',
    'contabilidadCXC' = 'contabilidadCXC',
}

/**
 * Credit Bureau Products
 */
export const creditBureauProducts =
    process.env.REACT_APP_CREDIT_BUREAU_PRODUCTS || '507,007';

/**
 * CFDI ApiKey
 */
export const cfdiApiKey =
    process.env.REACT_APP_CFDI_API_KEY || 'bjOT1n5AB85eSGz6VAKVFaM5Of7d4swv4zOV5FrC';

/**
 * POS Environment
 */
export const posFileStorageClient =
    process.env.REACT_APP_POS_FILE_STORAGE_CLIENT ||
    'a2703643-2453-4307-9921-bad959b0fee8';
export const posArchivingMonthsTime =
    process.env.REACT_APP_POS_ARCHIVING_MONTHS_TIME || '3';
export const posApiKey = process.env.REACT_APP_POS_API_KEY || '';

/**
 * CFDI Templates Ids Env
 */
export const cfdiTemplatesFileIds = {
    socialRegime:
        process.env.REACT_APP_SOCIAL_REGIME_TEMPLATE_ID ||
        '03c9e99b-128f-4c81-8386-30d30b4cd3e2',
    taxRegime:
        process.env.REACT_APP_TAX_REGIME_TEMPLATE_ID ||
        'fab25dba-79bd-402a-9ec3-3c2884103c56',
    cfdi:
        process.env.REACT_APP_USE_TYPE_TEMPLATE_ID ||
        'af24f473-9675-46aa-988e-7de3adf5f386',
};

/**
 * Digital signature environment
 */
export const dssFileStorageClient =
    process.env.REACT_APP_DSS_FILE_STORAGE_CLIENT ||
    'da6eed22-0957-493a-ac93-88b1b5b3c7c7';

/**
 * Customer Portal initial years for filters
 */
export const cpContactDataInitYearsFilters =
    process.env.REACT_APP_CP_REPORTS_INITIAL_YEARS || '2020,2022,2023';

/**
 * Customer Portal initial years for open requests filters
 */
export const cpOpenRequestsInitYearsFilters =
    process.env.REACT_APP_CP_OPEN_REQUESTS_REPORTS_INITIAL_YEARS || '2023';

/**
 * Customer Portal initial years for filters in marketing preferences
 */
export const cpMarketingPreferencesInitYearsFilters =
    process.env.REACT_APP_CP_REPORTS_INITIAL_YEARS_MP || '2023';

/**
 * Customer Portal initial years for filters in marketing preferences
 */
export const cpRenewalsInitYearsFilters =
    process.env.REACT_APP_CP_REPORTS_INITIAL_YEARS_RENEWALS || '2023';

/**
 * FileStorage Load module
 */
export const fssLoadMaxSizeBytes =
    process.env.REACT_APP_FSS_MAX_SIZE_BYTES || kilobyte * (oneThousand * ten);
export const maxAllowedFiles = process.env.REACT_APP_MAX_ALLOWED_FILES || twenty;
