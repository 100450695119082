import { useKeycloak } from "@react-keycloak/web";
import { Navigate, useLocation } from "react-router-dom";

import { getUserRole } from "../utils/keycloak-utils";
import { RouteDefinition } from "./routes.type";

export const PrivateRoute = ({
  component: Component,
  allowedRoles: roles,
}: RouteDefinition) => {
  const { keycloak } = useKeycloak();
  const location = useLocation();

  if (keycloak.authenticated) {
    if (roles) {
      if (roles.includes(getUserRole(keycloak) || "")) {
        return <Component />;
      } else {
        return (
          <Navigate
            to={{ pathname: "/forbidden" }}
            state={{ from: location }}
          />
        );
      }
    } else return <Component />;
  } else {
    const isLogout = localStorage.getItem("isLogout");
    if (location.search && location.pathname && !isLogout) {
      localStorage.setItem("params", location.search);
      localStorage.setItem("view", location.pathname);
    } else {
      localStorage.removeItem("isLogout");
    }
    return <Navigate to={{ pathname: "/login" }} state={{ from: location }} />;
  }
};
