import { useKeycloak } from "@react-keycloak/web";
import React from "react";

import { fileStorageLabels } from "./file-storage.labels";

import { getUserRole } from "../../utils/keycloak-utils";

/**
 * Hero used in the dealer online administration page
 * @param props
 */
const Hero: React.FC<{
  title: string;
  description: string;
  iconClass: string;
}> = ({ title, description, iconClass }) => {
  return (
    <div id="hero-dealer-online" className="u-pt-none@s u-pt-large">
      <header className="c-hero-teaser__heading">
        <div className="c-hero-teaser__title">
          <h1>
            <span className="c-hero-teaser__title-row">{title}</span>
          </h1>
        </div>
        <div className="c-hero-teaser__subtitle c-form-heading__title u-pv-xxsmall">
          <i
            className={`c-form-heading__icon  c-icon  c-icon--[${iconClass}]`}
            aria-hidden="true"
            role="img"
          ></i>
          <span>{description}</span>
        </div>
      </header>
    </div>
  );
};

/**
 * It is an element wrapper for OAM views.
 * This homologate the view spacings.
 * @param props JSX.Element | React.FC
 * @returns {JSX.Element}
 */
const HeroWrapper = (props: {
  children: JSX.Element;
  heroData?: {
    title: string;
    description: string;
    iconClass: string;
  };
}): JSX.Element => (
  <div
    data-component="hero-wrapper"
    className="o-component-wrapper u-mt-xsmall@s u-mt-large u-mb-large"
  >
    <div className="o-page-wrap">
      {props.heroData && (
        <Hero
          title={props.heroData.title}
          description={props.heroData.description}
          iconClass={props.heroData.iconClass}
        />
      )}
      <section className="o-content-section">{props.children}</section>
    </div>
  </div>
);

/**
 * File storage main documents view.
 */
export const FileStorage: React.FC = () => {
  const { keycloak } = useKeycloak();

  /**
   * User role in keycloak.
   */
  const userRole: string = getUserRole(keycloak) || "";

  return (
    <HeroWrapper
      heroData={{
        title: fileStorageLabels.hero.title,
        description: fileStorageLabels.hero.description,
        iconClass: fileStorageLabels.hero.iconClass,
      }}
    >
      <>
        <div>Hello {userRole} since microfrontend</div>
      </>
    </HeroWrapper>
  );
};
