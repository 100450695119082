import { AxiosResponse } from 'axios';

import { AppService } from '../../App.service';
import { AuthUsersResponse } from '../../interfaces/user-data.interface';
import { HttpMethod } from '../../utils/enums/http-method.enum';

/**
 * Class responsible for manage the API calls and data request of the Home View
 */
export class HomeService extends AppService {
    /**
     * Returns the user data.
     * @param id
     */
    public getUserData(id: string): Promise<AxiosResponse<AuthUsersResponse>> {
        return this.client.fetch({
            path: `/auth/users/${id}`,
            method: HttpMethod.GET,
        });
    }
}
